"use client";

import { WagmiProvider } from "wagmi";

import { AuthorizationProvider } from "@/context/authorizationContext";
import { RainbowKitProvider } from "@rainbow-me/rainbowkit";
import { useConfigContext } from "@/context/configContext";

export function Providers({ children }: { children: React.ReactNode }) {
  const { chainConfig } = useConfigContext();
  return (
    <WagmiProvider config={chainConfig}>
      <RainbowKitProvider>
        <AuthorizationProvider>{children}</AuthorizationProvider>
      </RainbowKitProvider>
    </WagmiProvider>
  );
}
