"use client";
import type React from "react";

import { Button, Modal } from "@/components";
import { useAuthorizationContext } from "@/context/authorizationContext";
import { reachOutMailHandler } from "@/utils/mail";
import { useChainModal, useConnectModal } from "@rainbow-me/rainbowkit";
import { SiweStatus } from "@/hooks/auth/useSiwe";

export default function AccessDeniedModal() {
  const { openChainModal } = useChainModal();
  const { openConnectModal } = useConnectModal();
  const {
    siweStatus,
    siwe,
    isAccessDeniedModalOpen,
    closeAccessDeniedModal,
    isOnCorrectNetwork,
    isUserInvestor,
    isConnected,
  } = useAuthorizationContext();

  const getContent = () => {
    if (!isConnected) {
      return (
        <p>
          To gain access to these features, please connect your wallet first.
        </p>
      );
    }

    if (!isOnCorrectNetwork) {
      return (
        <>
          <p>You are connected to the wrong network.</p>
          <p>Please change to the Mainnet network.</p>
        </>
      );
    }

    if (!isUserInvestor) {
      return (
        <>
          <p>Your account is not approved.</p>
          <p>Please reach out to our team to get access.</p>
        </>
      );
    }
    if (siweStatus !== SiweStatus.Authenticated) {
      return (
        <>
          <p>Your account is not yet signed in.</p>
          <p>Please sign in with your wallet</p>
        </>
      );
    }
  };

  const getButtonProps = () => {
    if (!isConnected) {
      return {
        onClick: openConnectModal,
        text: "Connect Wallet",
      };
    }

    if (!isOnCorrectNetwork) {
      return {
        onClick: openChainModal,
        text: "Switch Network",
      };
    }

    if (!isUserInvestor) {
      return {
        onClick: reachOutMailHandler,
        text: "Reach Out",
      };
    }

    return {
      onClick: () => siwe(),
      text: "Sign in",
    };
  };

  const { onClick, text } = getButtonProps();

  function handleOnClick() {
    if (onClick) {
      onClick();
    }
    closeAccessDeniedModal();
  }

  return (
    <Modal
      isOpen={isAccessDeniedModalOpen}
      hasCloseButton={true}
      title="Access Denied"
      isTitleCentered={true}
      onClose={closeAccessDeniedModal}
    >
      <div className="font-sans text-base flex flex-col items-center px-10">
        {getContent()}
        <Button className="mt-5" variant="primary" onClick={handleOnClick}>
          {text}
        </Button>
      </div>
    </Modal>
  );
}
