import { reachOutEmail } from "@/constants/mail";

export function reachOutMailHandler() {
  const encodedSubject = encodeURIComponent(reachOutEmail.subject);
  const encodedBody = encodeURIComponent(reachOutEmail.body);

  const mailtoLink = `mailto:${reachOutEmail.to}?subject=${encodedSubject}&body=${encodedBody}`;

  window.location.href = mailtoLink;
}
